import { head } from "lodash";
import { BooleanLiteral } from "typescript";

export interface IQuestion {
  question: string;
  type: string;
  id: number;
  description?: string;
  placeholder?: string;
  answer?: string;
  questionId: number;
  subheading?: string;
  defaultvalue?: string;
  updatedAt?: string;
}

export interface IAudio {
  heading: string;
  subheading: string;
  audiourl: string;
}
export enum NumberOfQuestion {
  Goals = 3,
  Strengths = 3,
  Achievements = 2,
  Weaknesses = 2,
  Failures = 2,
  "Int. Exp." = 1,
  Extracurriculars = 1,
  Checklist = 2,
}

export enum Status {
  LOCKED = "LOCKED",
  UNLOCKED = "UNLOCKED",
  INPROGESS = "INPROGRESS",
  COMPLETED = "COMPLETED",
}

export enum StatusColorMap {
  "LOCKED" = "brand-grey",
  "UNLOCKED" = "white",
  "INPROGRESS" = "brand-orange",
  "COMPLETED" = "brand-torquise",
}

export enum StatusColorMapLight {
  "LOCKED" = "brand-grey",
  "UNLOCKED" = "brand-darkblue",
  "INPROGRESS" = "brand-darkblue",
  "COMPLETED" = "brand-darkblue",
}

export enum StepColorMapLight {
  "LOCKED" = "white",
  "UNLOCKED" = "white",
  "INPROGRESS" = "brand-darkblue",
  "COMPLETED" = "white",
}

export enum CardColorMap {
  "LOCKED" = "brand-darkblue",
  "UNLOCKED" = "brand-darkblue",
  "INPROGRESS" = "brand-torquise",
  "COMPLETED" = "brand-darkblue",
}

export interface IPopup {
  heading: string;
  description: string;
  audioName: string;
  audioFile: string;
  videoFile: string;
  showVideo: boolean;
  publicType?: boolean
}
export interface ICategory {
  key: string;
  heading: string;
  type: string;
  name: string;
  questions: IQuestion[];
  nestedCategories: ISubCategory[];
  description: string;
  audioName?: string;
  audioFile?: string;
  popup?: IPopup;
  status: Status.COMPLETED | Status.INPROGESS | Status.LOCKED | Status.UNLOCKED;
  listitem?: any;
  stepName?: any;
  phaseType?: any;
  unlockedOn : any;
  timerange : any;
  deadline: any;
  completedOn : any;
}

export interface ISubCategory {
  key: string;
  name: string;
  heading: string;
  type: string;
  questions: IQuestion[];
  audioName?: string;
  audioFile?: string;
  popup?: IPopup;
  status?:
    | Status.COMPLETED
    | Status.INPROGESS
    | Status.LOCKED
    | Status.UNLOCKED;
}

export interface Compprops {
  nestedCategories: ISubCategory[];
  questions: IQuestion[];
  category: ICategory;
  phase: string;
  publicType: Boolean;
}
export interface Icomp {
  item: string;
  questions: IQuestion[];
}

export const comingSoon = ["phase50"];

export const allPhaseStatuses = [
  {
    "key": Status.LOCKED,
    "name": Status.LOCKED
  },
  {
    "key": Status.UNLOCKED,
    "name": Status.UNLOCKED
  },
  {
    "key": Status.INPROGESS,
    "name": Status.INPROGESS
  },
  {
    "key": Status.COMPLETED,
    "name": Status.COMPLETED
  },
];

export const questionData = [
  {
    questionId: 7,
    subCategory: "Goals",
    question: "Short Term Goals",
  },
  {
    questionId: 8,
    subCategory: "Goals",
    question: "Long Term Goals",
  },
  {
    questionId: 9,
    subCategory: "Goals",
    question: "Opportunity",
  },
  {
    questionId: 10,
    subCategory: "Strengths",
    question: "Strength 1",
  },
  {
    questionId: 11,
    subCategory: "Strengths",
    question: "Strength 2",
  },
  {
    questionId: 12,
    subCategory: "Strengths",
    question: "Strength 3",
  },
  {
    questionId: 13,
    subCategory: "Strengths",
    question: "Strength 4",
  },
  {
    questionId: 14,
    subCategory: "Strengths",
    question: "Strength 5",
  },
  {
    questionId: 15,
    subCategory: "Strengths",
    question: "Strength 6",
  },
  {
    questionId: 16,
    subCategory: "Strengths",
    question: "Strength 7",
  },
  {
    questionId: 17,
    subCategory: "Strengths",
    question: "Strength 8",
  },
  {
    questionId: 18,
    subCategory: "Strengths",
    question: "Strength 9",
  },
  {
    questionId: 19,
    subCategory: "Strengths",
    question: "Strength 1",
  },
  {
    questionId: 20,
    subCategory: "Achievements",
    question: "Achievement 1",
  },
  {
    questionId: 21,
    subCategory: "Achievements",
    question: "Achievement 2",
  },
  {
    questionId: 22,
    subCategory: "Achievements",
    question: "Achievement 3",
  },
  {
    questionId: 23,
    subCategory: "Achievements",
    question: "Achievement 4",
  },
  {
    questionId: 24,
    subCategory: "Achievements",
    question: "Achievement 5",
  },
  {
    questionId: 25,
    subCategory: "Achievements",
    question: "Achievement 5",
  },
  {
    questionId: 26,
    subCategory: "Achievements",
    question: "Achievement 6",
  },
  {
    questionId: 27,
    subCategory: "Achievements",
    question: "Achievement 7",
  },
  {
    questionId: 28,
    subCategory: "Achievements",
    question: "Achievement 8",
  },
  {
    questionId: 29,
    subCategory: "Achievements",
    question: "Achievement 9",
  },
  {
    questionId: 30,
    subCategory: "Weaknesses",
    question: "weakness 1",
  },
  {
    questionId: 31,
    subCategory: "Weaknesses",
    question: "weakness 2",
  },
  {
    questionId: 32,
    subCategory: "Weaknesses",
    question: "weakness 3",
  },
  {
    questionId: 33,
    subCategory: "Weaknesses",
    question: "weakness 4",
  },
  {
    questionId: 34,
    subCategory: "Weaknesses",
    question: "weakness 5",
  },
  {
    questionId: 35,
    subCategory: "Weaknesses",
    question: "weakness 6",
  },
  {
    questionId: 36,
    subCategory: "Weaknesses",
    question: "weakness 7",
  },
  {
    questionId: 37,
    subCategory: "Weaknesses",
    question: "weakness 8",
  },
  {
    questionId: 38,
    subCategory: "Weaknesses",
    question: "weakness 9",
  },
  {
    questionId: 39,
    subCategory: "Weaknesses",
    question: "weakness 10",
  },
  {
    questionId: 40,
    subCategory: "Failures",
    question: "Failure 1",
  },
  {
    questionId: 41,
    subCategory: "Failures",
    question: "Failure 2",
  },
  {
    questionId: 42,
    subCategory: "Failures",
    question: "Failure 3",
  },
  {
    questionId: 43,
    subCategory: "Failures",
    question: "Failure 4",
  },
  {
    questionId: 44,
    subCategory: "Failures",
    question: "Failure 5",
  },
  {
    questionId: 45,
    subCategory: "Failures",
    question: "Failure 6",
  },
  {
    questionId: 46,
    subCategory: "Failures",
    question: "Failure 7",
  },
  {
    questionId: 47,
    subCategory: "Failures",
    question: "Failure 8",
  },
  {
    questionId: 48,
    subCategory: "Failures",
    question: "Failure 9",
  },
  {
    questionId: 49,
    subCategory: "Failures",
    question: "Failure 10",
  },
  {
    questionId: 50,
    subCategory: "Int - Experience",
    question: "Int - Experience",
  },
  {
    questionId: 51,
    subCategory: "Extracurricular",
    question: "Extracurricular",
  },
  {
    questionId: 52,
    subCategory: "Checklist 1.0 vs 2.0",
    question: "Checklist 1.0 vs 2.0",
  },
  {
    questionId: 53,
    subCategory: "Why MBA",
    question: "Why MBA",
  },
];

export const allPhases = [
  {
    "key": "phase1",
    "name": "Phase 1 (Welcome Onboard)"
  },
  {
    "key": "phase2",
    "name": "Phase 2 (Call with Consultant)"
  },
  {
    "key": "phase3",
    "name": "Phase 3 (Content Gathering)"
  },
  {
    "key": "phase4",
    "name": "Phase 4 (Content Review)"
  },
  {
    "key": "phase5",
    "name": "Phase 5 (Branding)"
  },
  {
    "key": "phase6",
    "name": "Phase 6 (School Selection)"
  },
  {
    "key": "phase7",
    "name": "Phase 7 (Essays)"
  },
  {
    "key": "phase8",
    "name": "Phase 8 (Resume Resources)"
  },
  {
    "key": "phase9",
    "name": "Phase 9 (Interview Preparation)"
  }
];

export const allSteps = [
  {
    "key": "Phase1",
    "name": "Step 1 - Welcome Onboard"
  },
  {
    "key": "Phase2",
    "name": "Step 2 - Call with Consultant"
  },
  {
    "key": "Phase3",
    "name": "Step 3 - Content Gathering"
  },
  {
    "key": "Phase4",
    "name": "Step 4 - Content Review"
  },
  {
    "key": "Phase5",
    "name": "Step 5 - Branding"
  },
  {
    "key": "Phase6",
    "name": "Step 6 - School Selection"
  },
  {
    "key": "Phase7",
    "name": "Step 7 - Essays"
  },
  {
    "key": "Phase8",
    "name": "Step 8 - Resume & LOR"
  },
  {
    "key": "Phase9",
    "name": "Step 9 - Interview Preparation"
  }
];

export const staticResumeData = [
  {
    type: "file",
    fileName: "Download Resume Template Sample: 1",
    fileUrl: "https://mbaandbeyond.s3.amazonaws.com/static/product/phase8/Final+Resume+Format+-+v5.docx",
  },
  {
    type: "file",
    fileName: "Download Resume Template Sample: 2",
    fileUrl:
      "https://mbaandbeyond.s3.amazonaws.com/static/product/phase8/Resume+Sample+Template+2.docx",
  },
  {
    type: "file",
    fileName: "Download Resume Template Sample: 3",
    fileUrl:
      "https://mbaandbeyond.s3.amazonaws.com/static/product/phase8/Resume+Sample+Template+3.docx",
  },
]

export const lorData =
  {
    lorResources: [
      {
        heading: "LOR's and its importance",
        description: "In the MBA application journey, recommendation letters hold great importance, offering an external perspective on your character and fit for business school. Crafting compelling strength narratives is the key to showcasing your true self. Carefully selecting recommenders who can provide a well-rounded view of your qualities is essential, and this section offers guidance to help you make a strong, personal impact on your MBA application.",
        type: "audio",
        audioName: "LOR's, and its importance",
        audioFile: "https://mbaandbeyond.s3.amazonaws.com/static/product/phase8/LORs%2C+and+its+importance.mp3",
      },
      {
        heading: "How to choose recommenders for LOR's",
        description: "In the MBA application process, making the right choices about your recommenders is critical. It greatly influences how the admissions committee perceives you. Unless the school specifically requests peer recommendations, it's advisable to go with recommenders in higher positions. The ideal choices are individuals who know you well, can provide enthusiastic and positive endorsements, and ensure their recommendations are submitted on time.",
        type: "audio",
        audioName: "How to choose recommenders for LOR's",
        audioFile: "https://mbaandbeyond.s3.amazonaws.com/static/product/phase8/How+to+choose+recommenders+for+LORs.mp3",
      },
      {
        heading: "Aspects of LOR's",
        description: "Crafting impactful Letters of Recommendation (LORs) for MBA applications is a significant task. Start by understanding the specific prompts and your connection with the candidate. Write a thorough LOR that showcases the candidate's background, strengths, and areas for growth using real-life examples. It's crucial to maintain a professional tone and pay close attention to proofreading and submission guidelines. Remember, LORs should be in English, and only recommenders should create them, ultimately benefiting the MBA applicant.",
        type: "audio",
        audioName: "Aspects for LOR's",
        audioFile: "https://mbaandbeyond.s3.amazonaws.com/static/product/phase8/Aspects+of+LORs.mp3",
      },
      {
        heading: "Mistakes to avoid in LOR",
        description: "In MBA applications, your letters of recommendation (LORs) carry immense weight. To ensure they truly reflect your strengths, work closely with your recommenders. Share specific traits and examples, and provide a concise reference document for clarity. Emphasize the importance of backing your achievements with facts, meet deadlines, and let your recommender write the letter in their own words. This collaboration boosts your admission prospects, presenting a well-rounded view of your qualifications to the Admissions Committee.",
        type: "audio",
        audioName: "Mistakes of LOR's",
        audioFile: "https://mbaandbeyond.s3.amazonaws.com/static/product/phase8/Mistakes+to+avoid+in+LOR.mp3",
      }
    ],
    lorSamples: [
      {
        type: "file",
        fileName: "LOR EU",
        fileUrl: "https://mbaandbeyond.s3.amazonaws.com/static/product/phase8/LOR+EU.docx",
      },
      {
        type: "file",
        fileName: "LOR US",
        fileUrl:
          "https://mbaandbeyond.s3.amazonaws.com/static/product/phase8/LOR+US.docx",
      },
    ]
  }

export const staticDataForPhases = {
  phase3: [
    {
      heading: "School Resources",
      description:
        "Resume is the most important part of your application process and so, an effective resume highlighting not only your job profile but also your what role you played in that particular job and what impact it had on the organization is what needs to added and we will help you to breakdown your resume in parts which will lead you one step closer to your dream B - School",
      audioName: "Introduction to Resume building",
      type: "audio",
      audioFile:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase3/Resume.mp3",
    },
    {
      heading: "Tips for Resume",
      description:
        "Adding your preferences and skills can get quite overwhelming as to what should be added and what shouldn't be. A crisp resume is what every B- School looks for and sometimes unnecessary content can lead to a fallback in the admission process and hence a crisp resume highlighting all the necessary information about you is what we will be working on",
      type: "audio",
      audioName: "Tips for Resume",
      audioFile:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase3/SEG6_1_Resume.mp3",
    },
    {
      heading: "Transferable Skills",
      description:
        "You must have developed many skills, which could be technical or soft skills during your coursework which you want to be highlighted and added to your resume. You can reflect upon the skills mentioned in the file below and work on reflecting how you perceived them and how it helped you further.",
      type: "file",
      fileName: "Download Transferable Skills list",
      fileUrl:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase3/Resume+Help_Tranferable+Skills+and+Action+Verbs.pdf",
    },
    {
      heading: "Resume Sample",
      description:
        "Check the Resume sample to take reference and start building your own",
      type: "file",
      fileName: "Download Sample Resume",
      fileUrl:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase3/CV_Skill+Based_Sample.docx",
    },
  ],
  phase8: [
    {
      heading: "Introduction to Resume Building",
      description:
        "Resume is the most important part of your application process and so, an effective resume highlighting not only your job profile but also your what role you played in that particular job and what impact it had on the organization is what needs to added and we will help you to breakdown your resume in parts which will lead you one step closer to your dream B - School",
      audioName: "Introduction to Resume building",
      type: "audio",
      audioFile:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase3/Resume.mp3",
    },
    {
      heading: "Tips for Resume",
      description:
        "Adding your preferences and skills can get quite overwhelming as to what should be added and what shouldn't be. A crisp resume is what every B- School looks for and sometimes unnecessary content can lead to a fallback in the admission process and hence a crisp resume highlighting all the necessary information about you is what we will be working on",
      type: "audio",
      audioName: "Tips for Resume",
      audioFile:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase3/SEG6_1_Resume.mp3",
    },
    {
      heading: "Transferable Skills",
      description:
        "You must have developed many skills, which could be technical or soft skills during your coursework which you want to be highlighted and added to your resume. You can reflect upon the skills mentioned in the file below and work on reflecting how you perceived them and how it helped you further.",
      type: "file",
      fileName: "Download Transferable Skills list",
      fileUrl:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase3/Resume+Help_Tranferable+Skills+and+Action+Verbs.pdf",
    },
  ],
  phase9: [
    {
      heading: "Interview Introduction",
      description:
        "In this section you will be able to understand how well you have to prepare yourself  mentally for anything from start to the time the interview lasts",
      audioName: "Interview Introduction",
      type: "audio",
      audioFile:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase4/Interview+Introduction.mp3",
    },
    {
      heading:
        "Interview Sample 1 - With both professional and personal stories",
      description: "",
      type: "file",
      fileName:
        "Interview Sample 1 - With both professional and personal stories",
      fileUrl:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase4/Interview+Sample+-++With+both+Professional+and+Personal+angle.pdf",
    },
    {
      heading:
        "Interview Sample 2 - Professional angle only with an add on of ECs",
      description: "",
      type: "file",
      fileName:
        "Interview Sample 2 - Professional angle only with an add on of ECs",
      fileUrl:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase4/Interview+Sample+-++Professional+angle+only+with+a+tinge+of+ECs.pdf",
    },
    {
      heading: "Types of Interviews",
      description:
        "There are two types of interview questions: one is situation based questions and the other is non situation based questions. Cracking these two types of questions is how we are going to help you excel in the interview preparation.",
      audioName: "Types of Interviews",
      type: "audio",
      audioFile:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase4/Types+of+Interviews.mp3",
    },
    {
      heading: "Interview Resources",
      description:
        "Here are the Resources available which you have collected through content gathering and your essays which you need to be thorough with to prepare yourself for the interview.",
      audioName: "Interview Resources",
      type: "audio",
      audioFile:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase4/Resources+available+to+prepare+for+interviews.mp3",
    },
    {
      heading: "Question Bank",
      description: "",
      type: "file",
      fileName: "Download Question Bank",
      fileUrl:
        "https://mbaandbeyond.s3.amazonaws.com/content/Question_Bank_Interview_Prep_3664e5bc92.pdf?updated_at=2022-11-09T09:56:21.208Z",
    },
    {
      heading: "Interview Tips",
      description:
        "Here are some tips that will help you ace any surprise question asked  by the interviewer and understand how to act and answer",
      audioName: "Interview Tips",
      type: "audio",
      audioFile:
        "https://mbaandbeyond.s3.amazonaws.com/static/product/phase4/Tips.mp3",
    },
  ],
  interviewPrepQuestions : [
    {
      questionId: 1,
      questionName: "Walk me through your Resume.",
      timeLimit : 5,
      status : true,
      videoUrl:""
    },
    {
      questionId: 2,
      questionName: "What has been your most challenging academic experience so far?",
      timeLimit : 5,
      status : true,
      videoUrl:""
    },
    {
      questionId: 3,
      questionName: "What do you consider your greatest strength?",
      timeLimit : 5,
      status : true,
      videoUrl:""
    },
    {
      questionId: 4,
      questionName: "How have you demonstrated leadership in your career?",
      timeLimit : 5,
      status : true,
      videoUrl:""
    },
    {
      questionId: 5,
      questionName: "Describe a time when you failed to meet someone's expectations.",
      timeLimit : 5,
      status : true,
      videoUrl:""
    },
    {
      questionId: 6,
      questionName: "What is your short-term goal?",
      timeLimit : 5,
      status : true,
      videoUrl:""
    },
    {
      questionId: 7,
      questionName: "What do you do in your free time?",
      timeLimit : 5,
      status : true,
      videoUrl:""
    },
  ]
};

export const userJourneyDefaultData = {
  applicantGender : ["Male", "Female", "Others"],
  applicantEngineer : ["Yes", "No"],
  applicantStatusData : ["Active", "Pause", "Unresponsive", "Submitted app"],
  applicantManagerData : [
    {name:"Kanishka Varma", email: "kanishka@mbaandbeyond.com"},
    {name:"Kushalta Wageshwar", email: "kushalta@mbaandbeyond.com"},
    {name:"Palash Naidu", email: "palash@mbaandbeyond.com"},
    {name:"Rahat Gupta", email: "rahat@mbaandbeyond.com"},
    {name:"Shivani Vij", email: "shivani.v@mbaandbeyond.com"},
  ],
  assignedConsultantData : [
    {name:"Adhitya", email: "adhitya.raghavan@mbaandbeyond.com"},
    {name:"Andy", email: "andy@mbaandbeyond.com"},
    {name:"Akshita", email: "akshita@mbaandbeyond.com"},
    {name:"Ashmita", email: "ashmita@mbaandbeyond.com"},
    {name:"Aarti", email: "aarti@mbaandbeyond.com"},
    {name:"Anupinder", email: "anupinder@mbaandbeyond.com"},
    {name:"Arielle", email: "arielle@mbaandbeyond.com"},
    {name:"Carlos", email: "carlos@mbaandbeyond.com"},
    {name:"Cyrus", email: "cyrus@mbaandbeyond.com"},
    {name:"Dawn", email: "dawn@mbaandbeyond.com"},
    {name:"Devanshu", email: "devanshu@mbaandbeyond.com"},
    {name:"Emily Currin", email: "emily@mbaandbeyond.com"},
    {name:"Eric", email: "eric@mbaandbeyond.com"},
    {name:"Gauri", email: "gauri@mbaandbeyond.com"},
    {name:"Guillermo", email: "guillermo@mbaandbeyond.com"},
    {name:"Gustavo", email: "gustavo@mbaandbeyond.com"},
    {name:"Harshul", email: "harshul@mbaandbeyond.com"},
    {name:"Isshana Mahajan", email: "isshana@mbaandbeyond.com"},
    {name:"Ibrahim", email: "ibrahim.k@mbaandbeyond.com"},
    {name:"Inderpreet", email: "inderpreet@mbaandbeyond.com"},
    {name:"Jasmine", email: "jasmine.m@mbaandbeyond.com"},
    {name:"Josh", email: "josh@mbaandbeyond.com"},
    {name:"James", email: "james.ramsay@mbaandbeyond.com"},
    {name:"Kaustubh", email: "kaustubh@mbaandbeyond.com"},
    {name:"Kishan", email: "kishan@mbaandbeyond.com"},
    {name:"Kastur", email: "kastur@mbaandbeyond.com"},
    {name:"Leo", email: "leo@mbaandbeyond.com"},
    {name:"Lauren", email: "lauren@mbaandbeyond.com"},
    {name:"Marc", email: "marc.aurele@mbaandbeyond.com"},
    {name:"Marina", email: "marina@mbaandbeyond.com"},
    {name:"Marija", email: "marija.stamatovic@mbaandbeyond.com"},
    {name:"Melanie", email: "melanie@mbaandbeyond.com"},
    {name:"Nandita", email: "nandita@mbaandbeyond.com"},
    {name:"Neil Chong", email: "neil@mbaandbeyond.com"},
    {name:"Pablo", email: "pablo.gutierrez@mbaandbeyond.com"},
    {name:"Pietro", email: "pietro@mbaandbeyond.com"},
    {name:"Praveg", email: "praveg@mbaandbeyond.com"},
    {name:"Prasanna", email: "laxmi@mbaandbeyond.com"},
    {name:"Punit", email: "punit@mbaandbeyond.com"},
    {name:"Rajat", email: "rajat@mbaandbeyond.com"},
    {name:"Sanath", email: "sanath@mbaandbeyond.com"},
    {name:"Sangeetha", email: "sangeetha@mbaandbeyond.com"},
    {name:"Shivani", email: "shivani@mbaandbeyond.com"},
    {name:"Shahrukh", email: "shah.malik1@mbaandbeyond.com"},
    {name:"Shantanu", email: "shantanu@mbaandbeyond.com"},
    {name:"Sneha", email: "sneha.mz@mbaandbeyond.com"},
    {name:"Tiger", email: "tiger@mbaandbeyond.com"},
    {name:"Vidit", email: "vidit@mbaandbeyond.com"},
    {name:"Vinita", email: "vinita@mbaandbeyond.com"},
    {name:"Vidhan", email: "vidhan@Mbaandbeyond.com"},
  ],
  successFeeApplicantStatusData : ["Yes", "No"],
  previousStageData : ["Onboarded", "Welcome Answers", "Consultant Mapping", "Intro Call", "Content Gathering", "Content Review", "Branding", "School Selection", "Resume", "Essays", "Resume+Essays", "Done with application", "Interview Prepration", "Awaiting Result", "Essays/Schools"],
  currentStageData : ["Working On Welcome answers", "Working on Content Gathering", "Working on Branding ","School selection", "Working on Essay", "Working on Resume", "Working on Resume+Essays", "Submitted"],
  nextStageData : ["Onboarded", "Welcome Answers", "Consultant Mapping", "Intro Call", "Content Gathering", "Content Review", "Branding", "School Selection", "Resume", "Essays", "Resume+Essays", "Done with application", "Interview Prepration", "Awaiting Result", "Essays/Schools"],
  feedbackStatusData : ["Filled", "Shared", "Will fill later", "Unresponsive"]
}


// export const defaultData: ICategory[] = [
//   {
//     listitem: "Phase 1",
//     type: "category",
//     link: "/phase1",
//     heading: "Welcome Onboard",
//     nestedCategories: [],
//     status: Status.UNLOCKED,
//     audioPopup: {
//       heading: "",
//       subheading: "",
//       audiourl: "",
//     },
//     questions: [
//       {
//         question: "Description of your career",
//         type: "string",
//         id: 1,
//       },
//       {
//         question: "Personal aspirations",
//         type: "editor",
//         id: 2,
//       },
//       {
//         question: "Upload Resume",
//         type: "upload",
//         id: 3,
//       },
//       {
//         question: "Provide 3 email references here",
//         type: "multiple inputs",
//         id: 4
//       },
//       {
//         question: "Personality Test",
//         description: "click here to take the personality test and paste your link", //link of ps
//         type: "multiple inputs",
//         id: 4
//       }
//     ],
//   },
//   {
//     listitem: "Phase 2",
//     type: "category",
//     link: "/phase2",
//     heading: "Content gathering",
//     status: Status.INPROGESS,
//     questions: [],
//     audioPopup: {
//       heading: "",
//       subheading: "",
//       audiourl: "",
//     },
//     nestedCategories: [
//       {
//         heading: "Goals",
//         link: "/goals",
//         type: "sub-category",
//         questions: [
//           {
//             question: "Write your short term goals",
//             type: "editor",
//             id: 3,
//           },
//           {
//             question: "Write about your long term goals",
//             type: "editor",
//             id: 4,
//           },
//           {
//             question: "Opportunity",
//             type: "editor",
//             id: 4,
//           }
//         ],
//         audioPopup: {
//           heading: "",
//           subheading: "",
//           audiourl: "",
//         },
//       },
//       {
//         heading: "Strengths",
//         link: "/strengths",
//         type: "sub-category",
//         questions: [
//           {
//             question: "Strength 1",
//             type: "editor",
//             id: 3,
//           },
//           {
//             question: "Strength 2",
//             type: "editor",
//             id: 4,
//           },
//           {
//             question: "Strength 3",
//             type: "editor",
//             id: 4,
//           },
//           {
//             question: "Strength 4",
//             type: "editor",
//             id: 4,
//           },
//         ],
//         audioPopup: {
//           heading: "",
//           subheading: "",
//           audiourl: "",
//         },
//       },
//       {
//         heading: "Achievements",
//         link: "/achievements",
//         type: "sub-category",
//         questions: [
//           {
//             question: "Achievement 1",
//             type: "editor",
//             id: 3,
//           },
//           {
//             question: "Achievement 2",
//             type: "editor",
//             id: 4,
//           },
//           {
//             question: "Achievement 3",
//             type: "editor",
//             id: 4,
//           },
//           {
//             question: "Achievement 4",
//             type: "editor",
//             id: 4,
//           },
//         ],
//         audioPopup: {
//           heading: "",
//           subheading: "",
//           audiourl: "",
//         },
//       },
//       {
//         heading: "Weaknesses",
//         link: "/weaknesses",
//         type: "sub-category",
//         questions: [
//           {
//             question: "Weakness 1",
//             type: "editor",
//             id: 3,
//           },
//           {
//             question: "Weakness 1",
//             type: "editor",
//             id: 4,
//           },
//           {
//             question: "Weakness 3",
//             type: "editor",
//             id: 4,
//           },
//           {
//             question: "Weakness 4",
//             type: "editor",
//             id: 4,
//           },
//         ],
//         audioPopup: {
//           heading: "",
//           subheading: "",
//           audiourl: "",
//         },
//       },
//       {
//         heading: "Failures",
//         link: "/failure",
//         type: "sub-category",
//         questions: [
//           {
//             question: "Failure 1",
//             type: "editor",
//             id: 3,
//           },
//           {
//             question: "Failure 2",
//             type: "editor",
//             id: 4,
//           },
//           {
//             question: "Failure 3",
//             type: "editor",
//             id: 4,
//           },
//           {
//             question: "Failure 4",
//             type: "editor",
//             id: 4,
//           },
//         ],
//         audioPopup: {
//           heading: "",
//           subheading: "",
//           audiourl: "",
//         },
//       },
//       {
//         heading: "Experience",
//         link: "/experience",
//         type: "sub-category",
//         questions: [
//           {
//             question: "Write about your experience",
//             type: "editor",
//             id: 4,
//           },
//         ],
//         audioPopup: {
//           heading: "",
//           subheading: "",
//           audiourl: "",
//         },
//       },
//       {
//         heading: "Extracurriculars",
//         link: "/extravurriculars",
//         type: "sub-category",
//         questions: [
//           {
//             question: "Write down your hobbies",
//             type: "editor",
//             id: 4,
//           },
//         ],
//         audioPopup: {
//           heading: "",
//           subheading: "",
//           audiourl: "",
//         },
//       },
//       {
//         heading: "Your Plan",
//         link: "/yourplan",
//         type: "sub-category",
//         questions: [
//           {
//             question: "Where do you see yourself 5 years down the line",
//             type: "editor",
//             id: 4,
//           },
//         ],
//         audioPopup: {
//           heading: "",
//           subheading: "",
//           audiourl: "",
//         },
//       },
//     ],
//   },
//   {
//     listitem: "Phase 3",
//     type: "category",
//     link: "/phase3",
//     status: Status.LOCKED,
//     heading: "Essay",
//     nestedCategories: [],
//     questions: [
//       {
//         question: "Tell me about yourself",
//         type: "editor",
//         id: 4,
//       },
//     ],
//     audioPopup: {
//       heading: "",
//       subheading: "",
//       audiourl: "",
//     },
//   },
//   {
//     listitem: "Phase 4",
//     type: "category",
//     link: "/phase4",
//     heading: "LOR & resume",
//     status: Status.LOCKED,
//     nestedCategories: [],
//     questions: [
//       {
//         question: "Tell me about yourself",
//         type: "editor",
//         id: 6,
//       },
//     ],
//     audioPopup: {
//       heading: "",
//       subheading: "",
//       audiourl: "",
//     },
//   },
// ];
